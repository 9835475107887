<template>
  <v-container fluid>
    <TableViewComponent
      title="Table Expertises médicales"
      @addItemEvent="onItem()"
      addItemLabel="ajouter une expertise médicale"
      itemLabel="expertise médicale"
      itemsLabel="expertises médicales"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette expertise médicale ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '11', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
      :workflowStepsSpecific="steps"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { MedicalExpertiseService } from "@/service/practitioners/medical_expertise_service.js"
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { MedicalProfessionService } from "@/service/practitioners/medical_profession_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";



export default {
  name: "TableMedicalExpertise",
  components: { 
    TableViewComponent, },
  mixins: [SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service de la vue */
      service: null,
      serviceMedicalSpeciality: null,
      serviceMedicalProfession: null,

      /**les éléments */
      entities: [],

      /** Les étapes à afficher en worflow */
      steps: [
        "Choisir un périmètre",
        "Consulter la configuration",
      ],
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.medicalexpertises.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];
        // Récupère tous les statut
        let entities = await this.service.getAll();
        // Récupère l'ensemble des spécialités medicales
        let medicalSpecialities = await this.serviceMedicalSpeciality.getAll();
        // Récupère l'ensemble des profession médicales
        let medicalProfessions = await this.serviceMedicalProfession.getAll();


        // Parcours l'ensemble des entités pour l'affichage de la table
        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          if (entity.name == "___t")
          {
            console.error("entity test " + JSON.stringify(entity, null, 4));
          }

          // Récupération du nom de la spécialité médicale de l'expertise
          entity.medicalSpeciality = "-";
          if (entity.medicalSpecialtyId) {
            let found = medicalSpecialities.find((m) => m.id == entity.medicalSpecialtyId);
            if (found) {
              entity.medicalSpeciality = found.digitalName;
            }
          }

          // Récupération du nom de la profession médicale de l'expertise
          entity.medicalProfession = "-";
          if (entity.medicalProfessionCodeMos) {
            let found = medicalProfessions.find((m) => m.codeMos == entity.medicalProfessionCodeMos);
            if (found) {
              entity.medicalProfession = found.label;
            }
          }


          //action de consultation
          entity.view = () => {
            this.$router.push(
              routes.medicalexpertises.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.medicalexpertises.edit.root + "/" + entity.id
            );
          };

          //action de suppression
          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'expertise : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

        this.entities.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      return [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "name",
          default: true,
        },
        {
          text: "Nom profession",
          align: "start",
          sortable: true,
          value: "professionName",
          default: true,
        },
        {
          text: "Profession médicale",
          align: "start",
          sortable: true,
          value: "medicalProfession",
          default: true,
        },
        {
          text: "Spécialité médicale",
          align: "start",
          sortable: true,
          value: "medicalSpeciality", 
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditPracticioner];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditPracticioner, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadPracticioner];
    },
  },
  mounted() {
    // Instanciation des services
    this.service = new MedicalExpertiseService(this.$api.getMedicalExpertisesApi());
    this.serviceMedicalSpeciality = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi());
    this.serviceMedicalProfession = new MedicalProfessionService(this.$api.getMedicalProfessionApi());

    this.load();
  },
};
</script>

<style>
</style>